
.header {
  width: 100%;
  border-bottom: 3px solid #ededed;

  .logo {
    height: 50px;
    margin-left: 50px;
  }

}

.regPageWrapper {
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/backgroundColor.png");
  background-size: cover;
  overflow:scroll;
}

.eventHeader {
  margin-top: 20px;
  text-align: center;
  background-color: white;
  width: 100%;
  max-width: 750px;
  padding: 20px 80px;
}

.formElement {
  max-width: 750px;
  padding: 10px 40px 40px 40px;
  background-color: white;
}

.disabledFields {
  pointer-events: none;
  background-color: #fff;
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 750px) {
  .formElement {
    min-width: 80%;
  }
}
