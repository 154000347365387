

.checkInWrapper {
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  background-color: #F2F2F2;
}

.checkInHeader {
  background-image: url('../assets/checkinHeader.png');
  background-size: cover;
  padding: 20px;
  height: 200px;
  width: 100%;
  background-color: white;
  padding-left: 12.5%;
  padding-right: 12.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // border: 1px solid red;
}

.logoWrapper {
  position: relative;
  top: -30px;
  // right: -50px;
}

.logo {
  height: 40px;
}

.footer {
  background-color: #EAEAEA;
  // height: 100px;
  text-align: center;
  padding: 30px 10%;
}

@media screen and (max-width: 1024px) {
  .checkInHeader {
    padding-left: 5%;
    padding-right: 5%;
  }

  .logoWrapper {
    position: relative;
    top: -50px;
    // right: 0px;
  }

  .logo {
    height: 40px;
  }
}
