$fontSize: 17px;
$font-family: 'Avenir', 'Arial', sans-serif;
$font-family-bold: 'Avenir Bold', 'Arial Bold', sans-serif;
$font-family-medium: 'Avenir Bold', 'Arial Medium', sans-serif;

* {
  box-sizing: border-box;
  outline: none;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: auto;
  overflow: auto;
  font-size: $fontSize;
}

body {
  margin: 0;
  font-family: $font-family;
  font-size: $fontSize;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-padding {
  padding: 0 !important;
}

.flex {
  display: flex;
  flex: 1 1 auto;
  position: relative;

  .direction-column {
    flex-direction: column;
  }
}


.flex-center {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  justify-content: center;
  align-items: center;
}

.direction-column {
  flex-direction: column;
}

.flex.wrapper {
  height: 100%;
  width: 100%;
}

.vertical-center {
  align-items: center;
}

.horizontal-center {
  justify-content: center;
}


.pageWrapper {
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/background.png");
  background-size: cover;
  display: flex;
  align-items: center;
}


.formLabel {
  color: black !important;
}

.MuiFormHelperText-root.Mui-error {
  // color: green !important;
  color: black !important;
  opacity: .6 !important;
}
