.footerWrapper {
  background-color: rgb(230, 230, 230);
  margin: auto;
  margin-top: 30px;
  width: 100%;
}

.footerContainer {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  padding-top: 35px;
}

.linkList {
  margin: 15px 45px;
  color: #000

}

.bottomLogo {
  height: 50px;
  display: block;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .footerContainer {
    padding: 10px;
    max-width: 400px;
  }
  .bottomLogo {
    height: 40px;
    margin-top: 10px
  }

  .linkList {
    font-size: 1em;
    margin: 10px;
  }
}
